.button {
	margin-top: 70px;
	background-color: black;
	width: 200px;
	height: 50px;
	border: none;
	outline: none;
	color: white;
	font-size: 20px;
	font-family: Arial, Helvetica, sans-serif;
	border-radius: 12px;
	cursor: pointer;
	transition: all 0.3s;
}

.button:hover {
	border: 1px solid red;
	background: red;
	color: white;
}

.second {
	background: white;
	border: 1px solid red;
	color: red;
}
